<template>
  <div class="taux-carburant-create page-layout">
    <PageHeader
      title="Ajouter une taxe sur vehicule société (carburant)"
      :has-back="true"
      @back="goBack"
    />
    <md-card class="meep-form">
      <md-card-content>
        <div class="meep-form-column meep-form-single-column">
          <div class="meep-input">
            <md-field :class="{ 'md-invalid': errors.has('annee_min') }">
              <label>Année min *</label>
              <md-input
                v-model="form.annee_min"
                v-validate="'required|numeric'"
                name="annee_min"
                type="annee_min"
              >
              </md-input>
              <span class="md-error" v-show="errors.has('annee_min')">
                {{ errors.first("annee_min") }}
              </span>
            </md-field>
          </div>

          <!--annee min-->

          <md-field :class="{ 'md-invalid': errors.has('annee_max') }">
            <label>Année max *</label>
            <md-input
              v-model="form.annee_max"
              v-validate="'numeric'"
              name="annee_max"
              type="annee_max"
            >
            </md-input>
            <span class="md-error" v-show="errors.has('annee_max')">
              {{ errors.first("année_max") }}
            </span>
          </md-field>

          <!--type-->

          <md-field :class="{ 'md-invalid': errors.has('type') }">
            <label>Type *</label>
            <md-select v-model="form.type">
              <md-option value="essence">Essence</md-option>
              <md-option value="diesel">Diesel</md-option>
            </md-select>
            <span class="md-error" v-show="errors.has('type')">
              {{ errors.first("type") }}
            </span>
          </md-field>

          <!--montant-->

          <md-field :class="{ 'md-invalid': errors.has('montant') }">
            <label>Montant *</label>
            <md-input
              v-model="form.montant"
              v-validate="'required|decimal'"
              name="montant"
              type="text"
            >
            </md-input>
            <span class="md-error" v-show="errors.has('montant')">
              {{ errors.first("montant") }}
            </span>
          </md-field>
        </div>
      </md-card-content>

      <!-- Le bouton "envoyer" -->

      <md-card-actions>
        <md-button class="md-raised md-primary" @click.native="createCarburant">
          Ajouter une taxe
          <md-icon>
            add
          </md-icon>
        </md-button>
      </md-card-actions>
    </md-card>
  </div>
</template>

<script>
import calculatorsModel from "../../../../model/calculators";
import PageHeader from "@/components/PageHeader";

export default {
  components: { PageHeader },
  data() {
    return {
      isDebug: false,
      form: {
        annee_min: "",
        annee_max: "",
        type: "",
        montant: "",
      },
    };
  },
  methods: {
    goBack() {
      window.history.back();
    },
    createCarburant() {
      this.$validator.validateAll().then(result => {
        if (result) {
          calculatorsModel
            .createCarburant(this.form)
            .then(() => {
              this.$toasted.global.AppSucces({
                message: "Le carburant a bien été enregistré",
              });

              this.$router.push(
                "/admin/calculators/taxe-vehicule-societe-carburant/list"
              );
            })
            .catch(err => {
              this.$toasted.global.AppError({
                message: err.msg,
              });
            });
        } else {
          this.$toasted.global.AppInfo({
            message: "Vous devez remplir les champs manquants",
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.taux-carburant-create {
  .debug-log {
    max-height: 360px;
    overflow-y: scroll;
  }

  .md-card {
    margin-top: 32px;
    margin-bottom: 32px;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
